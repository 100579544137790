<template lang='pug'>
  v-content(style="padding-bottom: 50px;").main-content
    router-view.
    bottom-nav(v-if="isMobile")
    dashboard-core-footer(v-show="!isMobile || isMobile && $route.name === 'Profile'")
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
      BottomNav: () => import('./BottomNav'),
    },
    computed: {
      isMobile () {
        return this.$store.getters['users/getIsMobile']
      },
    },
  }
</script>
